Vue.use(VeeValidate);

Vue.use(VueSweetAlert.default);

Vue.filter('first_digit', function(value) {
    var stringLength = value.toString().length;
    return (stringLength < 2) ? "0" : value.toString().substring(stringLength - 2, stringLength - 1);
});

Vue.filter('second_digit', function(value) {
    var stringLength = value.toString().length;
    return (stringLength < 1) ? "0" : value.toString().substring(stringLength, stringLength - 1);
});

if ($("#ccrb-app").length != 0) {
    var app = new Vue({
        el: '#ccrb-app',
        data: {
            now: Math.trunc((new Date()).getTime() / 1000),
            date: Math.trunc(Date.parse(window.flashSaleEndsOn) / 1000),
            signup: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                method: "ajax",
                processing: false,
                errorMessage: null,
            },
            signin: {
                email: "",
                password: "",
                method: "ajax",
                processing: false,
                errorMessage: null,
            },
            purchase: {
                amount: 100,
                voucher: '',
                formSubmitted: false,
                formSubmitting: false,
                formErrors: [],
            },
            market: {
                market: {
                    asks: [],
                    bids: [],
                },
                btc: 0
            },
            address: {
                ethereum: null
            },
            bonuses: [
                { "id": 1, "usd": 500, "extra_percentage": 2 },
                { "id": 2, "usd": 5000, "extra_percentage": 5 },
                { "id": 3, "usd": 10000, "extra_percentage": 10 },
                { "id": 4, "usd": 25000, "extra_percentage": 20 },
                { "id": 5, "usd": 50000, "extra_percentage": 30 }
            ],
            ccrbRate: window.flashSalePrice
        },

        computed: {
            usdPrice: {
                get: function get() {
                    var usdPrice = this.purchase.amount * this.ccrbRate;
                    return usdPrice;
                },
                set: function(newValue) {
                    var amount = newValue / this.ccrbRate;
                    this.purchase.amount = amount;
                },
            },
            bonusCCRB: {
                get: function get() {
                    var extraAmount = 0;
                    for (var i = 0; i < this.bonuses.length; i++) {
                        extraAmount = this.usdPrice >= this.bonuses[i].usd ? (this.purchase.amount * this.bonuses[i].extra_percentage / 100) : extraAmount;
                    }
                    return extraAmount;
                }
            },
            seconds() {
                return (this.date - this.now) % 60;
            },
            minutes() {
                return Math.trunc((this.date - this.now) / 60) % 60;
            },
            hours() {
                return Math.trunc((this.date - this.now) / 60 / 60) % 24;
            },
            days() {
                return Math.trunc((this.date - this.now) / 60 / 60 / 24);
            },
            externalRate() {
                if (!this.purchase.amount || this.purchase.amount < 0) {
                    return 0;
                }

                var amountNeeded = new Number(this.purchase.amount);
                var btcRate = 0;
                var btcRates = [];
                var amountAvailable = new Number(0);
                if (this.market.market.asks.length == 0) {
                    return 0;
                }

                for (var i = 0; i < this.market.market.asks.length; i++) {
                    btcRates.push(this.market.market.asks[i]);

                    var askBTCRate = new Number(this.market.market.asks[i][0]);
                    var askCCRBAmount = new Number(this.market.market.asks[i][1]);
                    btcRate += askBTCRate;

                    if (amountNeeded < askCCRBAmount) {
                        amountAvailable += amountNeeded;
                    } else {
                        amountAvailable += askCCRBAmount;
                    }

                    if (amountAvailable >= amountNeeded) {
                        break;
                    }
                }


                btcRate /= btcRates.length;

                btc = this.market.btc;


                return (btcRate * btc).toFixed(2);
            }
        },
        mounted() {
            window.setInterval(() => {
                this.now = Math.trunc((new Date()).getTime() / 1000);
            }, 1000);
            axios.get('/ccrb/market').then(response => this.market = response.data);
            // this.fetchData();
        },
        methods: {
            fetchData: function fetchData() {
                this.loading = true;
                axios.get('/ccrb/tokensale')
                    .then(function(response) {
                        this.bonuses = response.data.bonuses;
                        this.loading = false;
                    }).catch(function(error) {
                        console.log(error);
                        this.loading = false;
                    });
            },

            bonusDetails: function bonusDetails() {
                var bonusDetails = '<div class="list-group no-margin">';

                this.bonuses.forEach(function(value, key) {
                    bonusDetails += '       <div class="list-group-item">';
                    bonusDetails += '           Above $' + value.usd + ': Get ' + value.extra_percentage + '% Extra CCRB';
                    bonusDetails += '       </div>';
                });

                bonusDetails += '   </div>';

                this.$swal({
                    title: "Bonus Tokens Details",
                    type: 'info',
                    html: bonusDetails
                });
            },
            redirectToCoinPayments: function redirectToCoinPayments(order) {
                this.$swal.showLoading();
                var coinPaymentsForm = document.forms.coinPaymentsForm;
                coinPaymentsForm.elements.cart_name.value = order.name + " : #" + order.id;
                coinPaymentsForm.elements.currency.value = order.price_base;
                coinPaymentsForm.elements.amountf.value = order.amount;
                coinPaymentsForm.elements.item_number.value = order.id;
                coinPaymentsForm.elements.item_name.value = order.name;
                coinPaymentsForm.submit();
            },

            validateSignin: function validateSignin(e) {
                this.$validator.validateAll();
                if (!this.errors.any()) {
                    this.submitSignin();
                }
            },

            submitSignin: function submitSignin() {
                var vm = this;
                vm.signin.errorMessage = "";
                vm.signin.processing = true;
                axios.post('/v1/auth', this.signin)
                    .then(function(response) {
                        location.reload();
                        vm.signin.processing = false;
                    }).catch(function(error) {
                        vm.signin.errorMessage = error.response.data;
                        vm.signin.processing = false;
                    });
            },

            validateSignup: function validateSignup(e) {
                this.$validator.validateAll();
                if (!this.errors.any()) {
                    this.submitSignup();
                }
            },

            submitSignup: function submitSignup() {
                var vm = this;
                vm.signup.errorMessage = "";
                vm.signup.processing = true;
                axios.post('/signup/ccrb', vm.signup)
                    .then(function(response) {
                        vm.signup.processing = false;
                        vm.signup.errorMessage = response.data.message;
                        location.reload();
                    }).catch(function(error) {
                        vm.signup.errorMessage = error.response.data.message;
                        vm.signup.processing = false;
                    });
            },

            validateBeforeSubmitCcrbBuy: function validateBeforeSubmitCcrbBuy(e) {
                this.$validator.validateAll();
                if (!this.errors.any()) {
                    this.submitCcrbBuy();
                }
            },

            submitCcrbBuy: function submitCcrbBuy() {
                var inst = this;
                inst.purchase.formSubmitting = true;

                axios.post('/ccrb/purchase', {
                    amount: inst.purchase.amount,
                    voucher: inst.purchase.voucher
                }).then(function(response) {
                    inst.$swal({
                        title: response.data.message,
                        text: 'Redirecting to payment gateway',
                        timer: 4000,
                        onOpen: function onOpen() {
                            inst.$swal.showLoading();
                        }
                    }).then(function(result) {
                        inst.redirectToCoinPayments(response.data.order)
                    }).catch(function(error) {
                        inst.redirectToCoinPayments(response.data.order)
                    });
                }).catch(function(error) {
                    if (error.response) {
                        inst.$swal('Oops...', error.response.data.message, 'error');
                        inst.purchase.formErrors = error.response.data;

                        if (inst.purchase.formErrors.voucherError) {
                            inst.purchase.voucher = '';
                        }
                        if (inst.purchase.formErrors.amountError) {
                            inst.purchase.amount = 100;
                        }
                    }
                    inst.purchase.formSubmitting = false;
                });
            }
        }

    });
}


(function() {

    // header on scroll
    const header = document.querySelectorAll('.header')[0];
    const scrollTop = 50;
    const breakpoint = 0;

    window.onscroll = function() {
        if(!$('header').hasClass('header')) {
            return;
        }
        let ww = window.innerWidth;
        if (ww >= breakpoint) {
            if (window.scrollY >= scrollTop) {
                header.classList.add('is-scroll')
            } else {
                header.classList.remove('is-scroll')
            }
        } else {
            header.classList.remove('is-scroll')
        }
    }

    showMobileNav = function() {
        $('.js-mobilenav').addClass('is-active');
        $('.js-mobilenav-close').show();
    }
    hideMobileNav = function() {
        $('.js-mobilenav').removeClass('is-active');
        $('.js-mobilenav-close').hide();
    }

    // mobile navigation
    $('.js-mobilenav-open').on('click', showMobileNav);
    $('.js-mobilenav-close').on('click', hideMobileNav);

    // navigation scroll to
    $('.header--menu-link[href^="#"]').click(function() {
        var $scrollTarget = $('[name="' + $.attr(this, 'href').substr(1) + '"]');
        if ($scrollTarget.length) {
            var headerH = $('header.header').height();
            hideMobileNav();
            $('html, body').animate({
                scrollTop: $scrollTarget.offset().top - headerH
            }, 1500);
            return false;
        }
    });


    // team navigation
    showActiveTabContent = function() {
        var activeTab = $('.team--navigation .active');
        let targetCat = $(activeTab).find('button').attr('data-show');
        $('.team--members li').hide();
        $('.team--members [data-cat="' + targetCat + '"]').show();
    }

    showActiveTabContent();

    $('.team--navigation button').on('click', function() {
        $('.team--navigation li').removeClass('active');
        $(this).parent('li').addClass('active');

        showActiveTabContent();
    });

    // account navigation
    showActiveAccountTabContent = function() {
        var activeTab = $('.account--navigation .active');
        let targetCat = $(activeTab).find('button').attr('data-show');
        $('.account-tab').hide();
        $('#' + targetCat + '-tab').show();
    }

    $('.account--navigation button').on('click', function() {
        $('.account--navigation li').removeClass('active');
        $(this).parent('li').addClass('active');
        showActiveAccountTabContent();
    });

    showActiveAccountTabContent();




    // honeypot animation
    const $mainItem = $('.clickableItem');
    let activeItem = $('.clickableItem.is-active');
    let activeDetails = $('.details.is-active');
    let delay = 700;

    setActive = function() {

        let $self = $(this);

        if ($self.hasClass('is-active')) {

            activeItem.removeClass('is-active');
            activeDetails.removeClass('is-active');

            return false;
        }

        let anyActive = $('.clickableItem.is-active');

        activeItem.removeClass('is-active');
        activeDetails.removeClass('is-active');

        // fix issue when clicking on child item
        if (($self).hasClass('clickableItem')) {
            activeItem = $self
        } else {
            activeItem = $self.parents('.clickableItem')
        }

        let id = activeItem.attr('data-show');
        activeDetails = $('[data-item="' + id + '"]');
        activeItem.addClass('is-active')

            !anyActive.length ? delay = 0 : delay = 700;

        setTimeout(function() {
            activeDetails.addClass('is-active')
        }, delay);
    }

    $mainItem.on('click', setActive);

    $(".clickableItem:first").trigger('click');

    $mainItem.hover(setActive);
    
    $(document).on("click", ".signup-modal", function() {
        $("#signupLabel").html($(this).data('title'));
    });

    // window.setTimeout(function() {
    //     if (getCookie("buy_ccrb_clicked") != "") {
    //         $("#buy-ccrb").modal();
    //         deleteCookie("buy_ccrb_clicked");
    //     }
    // }, 1000);

})();

function setCookie(cname, cvalue, time) {
    var d = new Date();
    d.setTime(d.getTime() + time);
    // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function setBuyCCRBCookie() {
    if ($("#signupLabel").text() != "Get free tokens") {
        setCookie("buy_ccrb_clicked", "1010", 5 * 60 * 1000);
    } else {
        deleteCookie("buy_ccrb_clicked");
    }
}
(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

var gaTrackingCode = (window.location.hostname).match("^ccrb.io") ? '111181966' : '62062668';

ga('create', 'UA-' + gaTrackingCode + '-1', 'auto');
ga('send', 'pageview');
if (window.beeoneUserId) {
    ga('set', 'userId', window.beeoneUserId);
}

if ((window.location.hostname).match("^ccrb.io")) {
    window._mfq = window._mfq || [];
    (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.async = true;
    mf.src = "//cdn.mouseflow.com/projects/97be25fe-f301-4ea2-ad3c-7e936c735234.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
    })();
}
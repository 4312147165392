jQuery(document).ready(function ($) {
    "use strict";

    $.fn.hasAttr = function (name) {
        return this.attr(name) !== undefined;
    };

    function handle_navigation() {
        if ($(window).width() >= 767) {
            $('ul.nav-cats li.dropdown, ul.nav-cats li.dropdown-submenu').hover(function () {
                $(this).addClass('open').find(' > .dropdown-menu').show();
            }, function () {
                $(this).removeClass('open').find(' > .dropdown-menu').hide();

            });
        }
        else {
            $('ul.nav-cats li.dropdown, ul.nav-cats li.dropdown-submenu').unbind('mouseenter mouseleave');
        }

        if ($(window).width() >= 767) {
            $('ul.nav-cats li.mega_menu_li, ul.mega_menu').hover(function () {
                $(this).addClass('open').find(' > .mega_menu').stop(true, true).show();
            }, function () {
                $(this).removeClass('open').find(' > .mega_menu').stop(true, true).hide();
            });
        }
        else {
            $('ul.nav-cats li.mega_menu_li, ul.mega_menu').unbind('mouseenter mouseleave');
            $('ul.nav-cats li.mega_menu_li').click(function () {
                $(this).find('.mega_menu').slideToggle();
            });
        }
    }

    handle_navigation();

    $(document).on('click', 'a[data-toggle="dropdown"]', function () {
        if ($(this).attr('href').indexOf('http') > -1) {
            window.location.href = $(this).attr('href');
        }
    });

    $(window).resize(function () {
        setTimeout(function () {
            handle_navigation();
        }, 200);
    });


    $(document).on('click', 'a[data-toggle="dropdown"]', function () {
        if ($(this).attr('href').indexOf('http') > -1) {
            window.location.href = $(this).attr('href');
        }
    });

});